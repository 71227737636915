<template>
  <ComingSoon />
</template>

<script>
export default {
  name: 'Billing',

  data() {
    return {
      cards: [
        {
          title: 'Basic'
        }
      ]
    }
  }
}
</script>

<style scoped></style>
